<template>
  <div>
    <div v-if="isAvailable">
      <div>
        <span class="badge badge-success">เปิดใช้อัตราจ่ายพิเศษ</span> - {{rateTitle}}
      </div>
      <table class="table table-sm table-info mb-0" style="width: auto;">
        <thead>
          <tr>
            <th width="200" class="text-center">ประเภท</th>
            <th width="200" class="text-center">เปิด/ปิด</th>
            <th width="200" class="text-center">จ่าย(บาท)</th>
            <th width="200" class="text-center">คอมฯ(%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="key in keys">
            <td class="text-center">{{numberType(key)}}</td>
            <td class="text-center">
              <i v-if="isOpenBet(key)" class="fas fa-check fa-lg text-success"></i>
              <i v-else class="fas fa-times fa-lg text-danger"></i>
            </td>
            <td class="text-right">{{ pay(key) | amountFormat }}</td>
            <td class="text-right">{{ com(key) | amountFormat }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <span class="badge badge-danger">ปิดใช้อัตราจ่าย</span> - {{rateTitle}}
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogCustomPayrate',
  props: ['description'],
  computed: {
    isAvailable() {
      return this.description?.isAvailable
    },
    rateTitle() {
      return this.description?.rateTitle
    },
    rates() {
      return this.description?.rates || {}
    },
    keys() {
      return Object.keys(this.rates)
    }
  },
  methods: {
    numberType(type) {
      return {
        threeNumberTop: '3 ตัวบน',
        threeNumberTode: '3 ตัวโต๊ด',
        twoNumberTop: '2 ตัวบน',
        twoNumberBottom: '2 ตัวล่าง',
        runTop: 'วิ่งบน',
        runBottom: 'วิ่งล่าง'
      }[type]
    },
    pay(type) {
      return this.rates?.[type]?.pay || 0
    },
    com(type) {
      return this.rates?.[type]?.discount || 0
    },
    isOpenBet(type) {
      return this.rates?.[type]?.isAvailable
    }
  }
}
</script>
