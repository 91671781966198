<template>
  <div>
    <div class="text-primary">รับของ: {{ marketTitle }} {{rateIds}}</div>
    <table class="table table-bordered table-info mb-0">
      <thead>
        <tr>
          <th :width="100/bets.length" v-for="bet in bets" class="text-center">{{ bet.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="bet in bets" width="12%" class="text-right">{{bet.set | amountFormat(0, '0')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'LogSetsReceive',
  props: ['description'],
  computed: {
    receive() {
      return this.description?.receives ?? {}
    },
    keys() {
      return Object.keys(this.receive)
    },
    marketId() {
      return this.description?.marketId || null
    },
    rateIds() {
      return this.description?.rateIds || []
    },
    markets() {
      return this.$store.state.markets
    },
    market() {
      return this.markets.find((m)=>{
        return m._id === this.marketId
      })
    },
    marketTitle() {
      return this.market?.marketTitle
    },
    bets() {
      return this.keys.map((key)=>{
        return {
          set: this.receive[key],
          name: {
            fiveNumber: '5 ตัวตรง',
            fourNumber: '4 ตัวตรง',
            threeNumber: '3 ตัวตรง',
            fourNumberTode: '4 ตัวโต๊ด',
            threeNumberTode: '3 ตัวโต๊ด',
            twoNumberPrefix: '2 ตัวหน้า',
            twoNumberSuffix: '2 ตัวหลัง',
            setReceiveAmount: 'จำนวนรับของ',
            setMaxBet: 'ซื้อสูงสุดต่อครั้ง'
          }[key]
        }
      })
    }
  }
}
</script>
