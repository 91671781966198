<template>
  <div>
    <span class="text-danger">ลบเลขอั้น</span> - <span class="badge badge-info">{{logNumberType}}</span>
    <div>
      ลบ:<span
        v-for="number in items"
        class="badge ml-1 badge-warning"
      >{{number}}</span>
    </div>
    <div>
      ทั้งหมด:<span
        v-for="(set, number) in allItems"
        class="badge ml-1 mb-1"
        :class="[{'badge-danger': set==='c'}, {'badge-warning': set==='h'}, {'badge-success': set!='c' && set!='h'}]"
      >{{number}}={{setText(set)}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogRoundDeleteSetNumber',
  props: ['description'],
  computed: {
    items() {
      return this.description.setNumbers
    },
    allItems() {
      return this.description.allNumbers
    },
    logNumberType() {
      return {
        threeNumberTop: '3 ตัวบน',
        threeNumberTode: '3 ตัวโต๊ด',
        twoNumberTop: '2 ตัวบน',
        twoNumberBottom: '2 ตัวล่าง',
        runTop: 'วิ่งบน',
        runBottom: 'วิ่งล่าง'
      }[this.description.code]
    }
  },
  methods: {
    setText(set) {
      const text = {
        c: 'ปิดรับ',
        h: 'จ่ายครึ่ง'
      }[set]

      return text || set
    }
  }
}
</script>
