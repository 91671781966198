<template>
  <div>
    <span class="text-primary">ตั้งค่ารับของรายตัว</span> - <span class="badge badge-info">{{logNumberType}}</span>
    <div>
      เพิ่ม:<span v-for="number in items" class="badge badge-success ml-1 mb-1">{{number}}={{newAmount | amountFormat(0)}}</span>
    </div>
    <div>
      ทั้งหมด:<span v-for="(amount, number) in allItems" class="badge badge-warning ml-1">{{number}}={{amount | amountFormat(0)}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogRoundReceiveNumber',
  props: ['description'],
  computed: {
    newAmount() {
      return this.description.amount
    },
    items() {
      return this.description.setNumbers
    },
    allItems() {
      return this.description.allNumbers
    },
    logNumberType() {
      return {
        threeNumberTop: '3 ตัวบน',
        threeNumberTode: '3 ตัวโต๊ด',
        twoNumberTop: '2 ตัวบน',
        twoNumberBottom: '2 ตัวล่าง',
        runTop: 'วิ่งบน',
        runBottom: 'วิ่งล่าง'
      }[this.description.code]
    }
  }
}
</script>
