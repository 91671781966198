<template>
  <div>
    <div class="text-primary">รับของตามชนิดหวย</div>
    <table class="table table-bordered table-info mb-0">
      <thead>
        <tr>
          <th class="text-center">หวย</th>
          <th v-for="key in keys" width="12%" class="text-center">{{numberType(key)}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="marketId in marketIds">
          <td class="text-center">{{ marketTitle(marketId) }}</td>
          <td v-for="key in keys" width="12%" class="text-right">{{numberReceive(key) | amountFormat}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'LogMarketReceive',
  props: ['description'],
  computed: {
    receive() {
      return this.description?.receive || []
    },
    keys() {
      return Object.keys(this.receive)
    },
    marketIds() {
      return this.description?.marketIds || []
    },
    markets() {
      return this.$store.state.markets
    }
  },
  methods: {
    numberType(type) {
      return {
        threeNumberTop: '3 ตัวบน',
        threeNumberTode: '3 ตัวโต๊ด',
        twoNumberTop: '2 ตัวบน',
        twoNumberBottom: '2 ตัวล่าง',
        runTop: 'วิ่งบน',
        runBottom: 'วิ่งล่าง'
      }[type]
    },
    numberReceive(type) {
      return this.receive?.[type] || 0
    },
    marketTitle(id) {
      const market = this.markets.find((m)=>{
        return m._id === id
      })

      return market?.marketTitle
    }
  }
}
</script>
