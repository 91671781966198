<template>
  <div>
    <LogReceiveNumber v-if="type==='receiveNumber'" :description="description" />
    <LogDeleteReceiveNumber v-else-if="type==='deleteReceiveNumber'" :description="description" />
    <LogRoundReceive v-else-if="type==='roundReceive'" :description="description" />
    <LogCloseRound v-else-if="type==='closeRound'" :description="description" />
    <LogCustomPayrate v-else-if="type==='customPayrate'" :description="description" />
    <LogRoundSetNumber v-else-if="type==='roundSetNumber'" :description="description" />
    <LogRoundDeleteSetNumber v-else-if="type==='roundDeleteSetNumber'" :description="description" />
    <LogMarketReceive v-else-if="type==='marketReceive'" :description="description" />
    <LogSetsReceive v-else-if="type==='setsReceive'" :description="description" />
    <LogSetsReceive v-else-if="type==='roundSetsReceive'" :description="description" />
    <span v-else>{{type}} - {{description}}</span>
  </div>
</template>
<script>
import LogReceiveNumber from './LogReceiveNumber'
import LogDeleteReceiveNumber from './LogDeleteReceiveNumber'
import LogRoundReceive from './LogRoundReceive'
import LogCloseRound from './LogCloseRound'
import LogCustomPayrate from './LogCustomPayrate'
import LogRoundSetNumber from './LogRoundSetNumber'
import LogRoundDeleteSetNumber from './LogRoundDeleteSetNumber'
import LogMarketReceive from './LogMarketReceive'
import LogSetsReceive from './LogSetsReceive'

export default {
  name: 'LoggerDescription',
  components: {
    LogReceiveNumber,
    LogDeleteReceiveNumber,
    LogRoundReceive,
    LogCloseRound,
    LogCustomPayrate,
    LogRoundSetNumber,
    LogRoundDeleteSetNumber,
    LogMarketReceive,
    LogSetsReceive
  },
  props: ['type', 'description']
}
</script>
