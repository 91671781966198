<template>
  <div>
    <div v-if="useCustomClose">
      <span class="badge badge-danger">ตั้งเวลาปิดรับ</span> - {{closeTime}}
    </div>
    <div v-else>
      <span class="badge badge-warning">ปิดรับตามบริษัท</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogCloseRound',
  props: ['description'],
  computed: {
    useCustomClose() {
      return this.description.useCustomClose
    },
    closeTime() {
      return this.description.closeTime
    }
  }
}
</script>
