<template>
  <div>
    <div class="text-success">ตั้งค่ารับของ</div>
    <table class="table table-bordered table-success mb-0">
      <thead>
        <tr>
          <th v-for="key in keys" width="15%" class="text-center">{{numberType(key)}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="key in keys" class="text-right">{{numberReceive(key) | amountFormat}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'LogRoundReceive',
  props: ['description'],
  computed: {
    keys() {
      return Object.keys(this.description)
    }
  },
  methods: {
    numberType(type) {
      return {
        threeNumberTop: '3 ตัวบน',
        threeNumberTode: '3 ตัวโต๊ด',
        twoNumberTop: '2 ตัวบน',
        twoNumberBottom: '2 ตัวล่าง',
        runTop: 'วิ่งบน',
        runBottom: 'วิ่งล่าง'
      }[type]
    },
    numberReceive(type) {
      return this.description?.[type] || 0
    }
  }
}
</script>
